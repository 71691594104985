<template>
  <div v-if="selectedColumns" class="duplication-row pl-24 pr-24 txt-font-din-regular flex align-center">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <span v-html="$t('components.description.duplicateEntriesFound', { count: getDuplicateCount() })"></span>
    <a-button class="ml-20 txt-font-din-regular txt-orange lh-1" type="link" @click="clearHidden">
      <a-icon type="close-circle" theme="filled" class="txt-12"/>
      {{ $t('components.description.clearDuplicateFilter') }}
    </a-button>
  </div>
</template>

<script>
export default {
  name: 'DuplicationCount',
  props: {
    hot: {
      type: Object,
      required: false,
      default: () => undefined
    },
    hidden: {
      type: Array,
      required: true
    },
    selectedColumns: {
      type: [Boolean, Array],
      default: false
    },
    filtered: {
      type: Boolean,
      default: true
    },
    filteredHiddenRows: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    selectedColumns: {
      handler() {
        this.hot.render();
      },
      deep: true
    },
    filteredHiddenRows: {
      handler() {
        this.hot.render();
      },
      deep: true
    }
  },
  mounted() {
    this.$root.$on('clearAllDataEntryFilters', () => this.clearHidden());
    this.$root.$on('clearDataEntryFilter', () => this.clearFilterNotDuplicates());
  },
  methods: {
    getDuplicateCount() {
      const plugin = this.hot.getPlugin('hiddenRows');
      const hiddenRows = plugin.getHiddenRows() || [];
      const hiddenRowsCount = hiddenRows.filter((item) => item != null).length;
      return this.hot.countRows() - hiddenRowsCount;
    },
    clearHidden() {
      this.$emit('clearDuplicateFilter');
      this.hot.batch(() => {
        let hiddenRows = this.hidden;
        if (this.filtered) {
          hiddenRows = this.filteredHiddenRows;
        }
        const plugin = this.hot.getPlugin('hiddenRows');
        plugin.showRows(hiddenRows);
        this.hot.render();
      });
    },
    clearFilterNotDuplicates() {
      if (!this.selectedColumns) {
        const plugin = this.hot.getPlugin('hiddenRows');
        plugin.showRows(this.hidden);
        this.hot.render();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .duplication-row {
    background: #fde2c4;
    border-top: 1px solid #fbc17e;
    border-bottom: 1px solid #fbc17e;
  }
</style>
